var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-table",
    {
      attrs: {
        data: _vm.dataList,
        "header-cell-style": { color: "#333333", background: "#EFF6FF" },
        height: "100%",
        border: "",
      },
    },
    [
      _c("el-table-column", {
        attrs: { label: "序号", type: "index", width: "60", align: "center" },
      }),
      _c("el-table-column", {
        attrs: { label: "供货日期", prop: "date", align: "center" },
      }),
      _c("el-table-column", {
        attrs: { label: "结转日期", prop: "convert_date", align: "center" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [
                _c("span", [_vm._v(_vm._s(scope.row.convert_date || "-"))]),
              ]
            },
          },
        ]),
      }),
      _c("el-table-column", {
        attrs: { label: "结转状态", prop: "status_txt", align: "center" },
      }),
      _c("el-table-column", {
        attrs: { label: "结转金额", prop: "total_amount", align: "center" },
      }),
      _c("el-table-column", {
        attrs: {
          label: "运费金融手续费",
          prop: "freight_service_fee",
          align: "center",
        },
      }),
      _c("el-table-column", {
        attrs: {
          label: "服务费金融手续费",
          prop: "purchase_service_fee",
          align: "center",
        },
      }),
      _c("el-table-column", {
        attrs: { label: "其他扣款", prop: "other_amount", align: "center" },
      }),
      _c("el-table-column", {
        attrs: { label: "售后扣款", prop: "aftersale_amount", align: "center" },
      }),
      _c("el-table-column", {
        attrs: {
          label: "复核运费退款",
          prop: "qc_freight_amount",
          align: "center",
        },
      }),
      _c("el-table-column", {
        attrs: {
          label: "复核服务费退款",
          prop: "qc_purchase_amount",
          align: "center",
        },
      }),
      _c("el-table-column", {
        attrs: {
          label: "运费结算金额",
          prop: "freight_amount",
          align: "center",
        },
      }),
      _c("el-table-column", {
        attrs: {
          label: "服务费结算金额",
          prop: "purchase_amount",
          align: "center",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }