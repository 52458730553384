<template>
  <el-table
    :data="dataList"
    :header-cell-style="{ color: '#333333', background: '#EFF6FF' }"
    height="100%"
    border
  >
    <el-table-column label="序号" type="index" width="60" align="center">
    </el-table-column>
    <el-table-column label="供货日期" prop="date" align="center">
    </el-table-column>
    <el-table-column label="结转日期" prop="convert_date" align="center">
      <template slot-scope="scope">
        <span>{{ scope.row.convert_date || "-" }}</span>
      </template>
    </el-table-column>
    <!-- <el-table-column
      label="集配中心"
      prop="logistic_business_name"
      align="center"
      min-width="120"
      show-overflow-tooltip
    >
      <template slot-scope="scope">
        <span>{{ scope.row.logistic_business_name || "-" }}</span>
      </template>
    </el-table-column>
    <el-table-column
      label="城市仓"
      prop="logistics_name"
      align="center"
      min-width="120"
      show-overflow-tooltip
    ></el-table-column>
    <el-table-column
      label="城市仓持卡人"
      prop="bank_name"
      align="center"
      min-width="100"
    >
      <template slot-scope="scope">
        <span>{{ scope.row.bank_name || "-" }}</span>
      </template>
    </el-table-column>
    <el-table-column
      label="自提点"
      prop="delivery_name"
      align="center"
      min-width="120"
      show-overflow-tooltip
    >
      <template slot-scope="scope">
        <span>{{ scope.row.delivery_name || "-" }}</span>
      </template>
    </el-table-column> -->
    <el-table-column label="结转状态" prop="status_txt" align="center">
    </el-table-column>
    <el-table-column
      label="结转金额"
      prop="total_amount"
      align="center"
    ></el-table-column>
    <el-table-column
      label="运费金融手续费"
      prop="freight_service_fee"
      align="center"
    >
    </el-table-column>
    <el-table-column
      label="服务费金融手续费"
      prop="purchase_service_fee"
      align="center"
    >
    </el-table-column>
    <el-table-column label="其他扣款" prop="other_amount" align="center">
    </el-table-column>
    <el-table-column label="售后扣款" prop="aftersale_amount" align="center">
    </el-table-column>
    <el-table-column
      label="复核运费退款"
      prop="qc_freight_amount"
      align="center"
    >
    </el-table-column>
    <el-table-column
      label="复核服务费退款"
      prop="qc_purchase_amount"
      align="center"
    >
    </el-table-column>
    <el-table-column label="运费结算金额" prop="freight_amount" align="center">
    </el-table-column>
    <el-table-column
      label="服务费结算金额"
      prop="purchase_amount"
      align="center"
    >
    </el-table-column>
  </el-table>
</template>

<script>
import iconImage from "@/assets/image/icons/download@2x.png";
import { getCityReconciliationListExport } from "@/api/general/reconciliation-manage/index";

export default {
  name: "TableList",
  props: {
    dataList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      iconImage,
    };
  },
  methods: {
    /**
     * 导出文件
     */
    async onHandleExport(row, key) {
      this.$message.success({
        message: "正在导出，请耐心等待",
        duration: 999,
      });
      const params = {
        id: row.id,
        key,
      };
      try {
        await getCityReconciliationListExport(params);
        this.$message.success("导出成功~");
      } catch (err) {
        this.$message.error("导出失败");
        console.log("ajax getCityReconciliationListExport err", err);
      } finally {
        this.$message.closeAll();
      }
    },
    // /**
    //  * 查看详情
    //  */
    // onHandleClick(row) {
    //   const query = {
    //     id: row.id,
    //   };
    //   this.$router.push({
    //     path: "/city-reconciliation-manage/details",
    //     query,
    //   });
    // },
  },
};
</script>

<style lang="scss" scoped></style>
