/** 结转状态 */
export const OVER_STATUS = {
  todo: {
    value: 0,
    label: '未结转'
  },
  done: {
    value: 1,
    label: '已结转'
  }
}
