<template>
  <section class="page-wrap">
    <!-- 搜索模块 start -->
    <section class="form jn-form-style">
      <div class="form-item btn-wrap">
        <el-button
          type="primary"
          size="medium"
          icon="el-icon-refresh"
          title="重置"
          jn-refresh-btn
          @click="onRefresh"
        ></el-button>
      </div>

      <div class="form-item">
        <el-date-picker
          type="daterange"
          range-separator="至"
          start-placeholder="结算日期起"
          end-placeholder="结算日期止"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          align="right"
          v-model="dataTime1"
          @change="changeDate1"
          :picker-options="pickerOptions"
        >
        </el-date-picker>
      </div>

      <div class="form-item">
        <el-date-picker
          type="daterange"
          range-separator="至"
          start-placeholder="供货日期起"
          end-placeholder="供货日期止"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          align="right"
          v-model="dataTime2"
          @change="changeDate2"
          :picker-options="pickerOptions"
        >
        </el-date-picker>
      </div>

      <div class="form-item">
        <el-select
          placeholder="选择结转状态"
          v-model="formData.status"
          clearable
        >
          <el-option
            v-for="item in overStatusList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="form-item">
        <el-button
          slot="suffix"
          type="primary"
          size="medium"
          icon="el-icon-search"
          @click="onHandleSearch"
          >查询</el-button
        >
        <el-button
          @click="onHandleExport"
          :disabled="!dataList.length || disabledExport"
          type="warning"
          icon="el-icon-download"
          >导出</el-button
        >
      </div>
    </section>
    <!-- 搜索模块 end -->

    <!-- 列表数据 start -->
    <section class="content" v-loading="loading">
      <table-list :dataList="dataList"></table-list>
    </section>
    <!-- 列表数据 end -->

    <!-- 分页 start -->
    <div class="pagination">
      <el-pagination
        type="primary"
        :current-page="formData.page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="formData.pageSize"
        :total="total"
        @size-change="onHandleSizeChange"
        @current-change="onHandleCurrentChange"
        layout="total, sizes, prev, pager, next, jumper"
        background
      >
      </el-pagination>
    </div>
    <!-- 分页 end -->
  </section>
</template>

<script>
import cloneDeep from "lodash/cloneDeep";
import TableList from "./modules/table-list";
import {
  getSettlementListOfTdc,
  getSettlementListOfTdcExport,
} from "@/api/tdc/index";
import { OVER_STATUS } from "../utils/enum";
import { downLoadFlowBlob } from "@/utils/tools/base";

export default {
  name: "TdcAccountList",
  components: {
    TableList,
  },
  data() {
    return {
      loading: false,
      disabledExport: false, // 导出禁用
      overStatusList: Object.values(OVER_STATUS), // 结转状态下拉
      dataTime1: [], // 时间对象
      dataTime2: [], // 时间对象
      formData: {
        start_convert_date: "", // 结算日期起
        end_convert_date: "", // 结算日期止
        start_date: "", // 供货日期起
        end_date: "", // 供货日期止
        status: "", // 结转状态
        page: 1,
        pageSize: 10,
      }, // 表单数据
      formDataClone: {}, // 拷贝一份数据，用于重置
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      }, // 时间范围
      dataList: [], // 列表数据
      total: 0,
    };
  },
  mounted() {
    this.onInitData();
  },
  methods: {
    /**
     * 初始化数据
     */
    onInitData() {
      this.formDataClone = cloneDeep(this.formData);
      this.onHandleSearch();
    },
    /**
     * 查询数据
     */
    onHandleSearch() {
      this.dataList = [];
      this.total = 0;
      this.ajaxGetCityReconciliationList();
    },
    /**
     * 每页条数发生改变
     */
    onHandleSizeChange(val) {
      this.formData.page = 1;
      this.formData.pageSize = val;
      this.onHandleSearch();
    },
    /**
     * 页码发生改变
     */
    onHandleCurrentChange(val) {
      this.formData.page = val;
      this.onHandleSearch();
    },
    /**
     * 重置表单数据
     */
    onRefresh() {
      this.dataTime1 = [];
      this.dataTime2 = [];
      this.deliveryList = [];
      this.formData = {
        ...this.formDataClone,
      };
      this.onHandleSearch();
    },
    /**
     * 获得结算详情列表
     */
    async ajaxGetCityReconciliationList() {
      this.loading = true;
      const params = { ...this.formData };
      try {
        const { data } = await getSettlementListOfTdc(params);
        console.log("ajax getSettlementListOfTdc", data);
        const { total, data: list } = data;
        this.total = total;
        this.dataList = list;
      } catch (err) {
        console.log("ajax getSettlementListOfTdc err", err);
      } finally {
        this.loading = false;
      }
    },
    /**
     * 导出数据（下载中心）
     */
    async onHandleExport() {
      this.disabledExport = true;
      this.$message.success({
        message: "正在导出，请耐心等待",
        duration: 99999999,
      });
      try {
        const params = {
          ...this.formData,
        };
        delete params.page;
        delete params.pageSize;
        const res = await getSettlementListOfTdcExport(params);
        downLoadFlowBlob(res, res?.filename);
      } catch (err) {
        console.log("ajax getSettlementListOfTdcExport err", err);
      } finally {
        this.disabledExport = false;
        this.$message.closeAll();
      }
    },
    /**
     * 日期发生了变化
     */
    changeDate1(data) {
      this.formData.start_convert_date = data?.[0] || "";
      this.formData.end_convert_date = data?.[1] || "";
    },
    /**
     * 日期发生了变化
     */
    changeDate2(data) {
      this.formData.start_date = data?.[0] || "";
      this.formData.end_date = data?.[1] || "";
    },
  },
};
</script>

<style lang="scss" scoped>
.page-wrap {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 10px;
  box-sizing: border-box;
  .form {
    padding: 13px 15px;
  }
  .content {
    flex: 1;
    overflow-y: hidden;
  }
  .pagination {
    padding: 10px 20px;
    box-sizing: border-box;
  }
}
</style>
