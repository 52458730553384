import { BASE } from "../../index";
import axios from "../../http";

const BASE_URL = BASE.PRO1;
const EXPORT_CENTER = BASE.EXPORT_CENTER;

/**
 * 总控-财务管理-城市仓对账管理-顶部卡片
 * @param {*} params
 */
export const getCityReconciliationCard = (params = {}) => {
  return axios.post(`${BASE_URL}/city/reconciliation/card`, params);
};

/**
 * 总控-财务管理-城市仓结算列表
 * @param {*} params
 */
export const getCityReconciliationList = (params = {}) => {
  return axios.post(`${BASE_URL}/settlement/logistics/list`, params);
};

/**
 * 总控-财务管理-城市仓结算列表-导出
 * @param {*} params
 */
export const getCityReconciliationListExport = (params = {}) => {
  return axios.post(`${EXPORT_CENTER}/bi/settlement/logistics/list/export`, params);
};

/**
 * 总控-财务管理-城市仓结算详情
 * @param {*} params
 */
export const getCityReconciliationDetail = (params = {}) => {
  return axios.post(`${BASE_URL}/settlement/logistics/order/list`, params);
};

/**
 * 总控-财务管理-城市仓结算详情-导出
 * @param {*} params
 */
export const getCityReconciliationDetailExport = (params = {}) => {
  return axios.post(`${EXPORT_CENTER}/bi/settlement/logistics/order/export`, params);
};

/**
 * 总控-财务管理-供货商对账管理-顶部卡片
 * @param {*} params
 */
export const getSupplierReconciliationCard = (params = {}) => {
  return axios.post(`${BASE_URL}/supplier/reconciliation/card`, params);
};

/**
 * 总控-财务管理-供货商对账管理-列表
 * @param {*} params
 */
export const getSupplierReconciliationList = (params = {}) => {
  return axios.post(`${BASE_URL}/supplier/reconciliation/list`, params);
};

/**
 * 总控-财务管理-供货商对账管理-列表-导出
 * @param {*} params
 */
export const getSupplierReconciliationListExport = (params = {}) => {
  return axios.post(`${BASE_URL}/supplier/reconciliation/list/export`, params);
};

/**
 * 总控-财务管理-供货商结算详情
 * @param {*} params
 */
export const getSupplierReconciliationDetail = (params = {}) => {
  return axios.post(`${BASE_URL}/supplier/reconciliation/details/list`, params);
};
