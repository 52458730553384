import axios from '../http';
const BASE_URL = process.env.VUE_APP_TDC_URL;

/**
 * 城市仓后台-结算管理-结算详情-列表
 * @param {*} params
 * @returns
 */
export function getSettlementLogisticsOfTdc(params = {}) {
  return axios(
    {
      method: 'POST',
      url: `${BASE_URL}/settlement/logistics/order/list`,
      data: params
    }
  );
};

/**
 * 城市仓后台-结算管理-结算详情-导出
 * @param {*} params
 * @returns
 */
export function getSettlementLogisticsOfTdcExport(params = {}) {
  return axios(
    {
      method: 'POST',
      url: `${BASE_URL}/settlement/logistics/order/list/export`,
      responseType: "blob",
      data: params
    }
  );
};

/**
 * 城市仓后台-结算管理-结算列表
 * @param {*} params
 * @returns
 */
export function getSettlementListOfTdc(params = {}) {
  return axios(
    {
      method: 'POST',
      url: `${BASE_URL}/settlement/logistics/list`,
      data: params
    }
  );
};

/**
 * 城市仓后台-结算管理-结算列表-导出
 * @param {*} params
 * @returns
 */
export function getSettlementListOfTdcExport(params = {}) {
  return axios(
    {
      method: 'POST',
      url: `${BASE_URL}/settlement/logistics/list/export`,
      responseType: "blob",
      data: params
    }
  );
};

